import { combineReducers } from "redux";
import appointmentReducer from "./appointmentReducer";
import authReducer from "./authReducer";

const rootReducer = combineReducers({
  authStore: authReducer,
  appointmentStore: appointmentReducer,
});

export default rootReducer;
