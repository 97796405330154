import React, { Component } from "react";
import packageJson from "../../../package.json";
import verdantImage from "../../assets/solar_panel_bg.jpg";
import verdantLogo from "../../assets/verdant_logo.png";
import { withRouter } from "../../navigator/NavigateWrapper";
import { withSecurity } from "../../navigator/SecurityWrapper";
import Button from "../base/Button";
import Input from "../base/Input";
import Overlay from "../base/Overlay";
import {
  getUserInfo,
  handleLoginCondition,
  signIn,
  verifyOTP,
} from "../store/actions/authActions";

enum AuthState {
  "MOBILE" = "mobile",
  "OTP" = "otp",
}

interface Props {
  location: any;
  navigate: any;
}

interface State {
  loading: boolean;
  isLoggingIn: boolean;
  page: AuthState;
  loginState: LoginState;
  loginStateError: LoginStateError;
}

export interface LoginState {
  phoneNumber: string;
  otpCode: string;
}

export interface LoginStateError {
  phoneNumberError: string;
  otpCodeError: string;
}

class LoginScreen extends Component<Props> {
  state: State = {
    loading: false,
    isLoggingIn: false,
    page: AuthState.MOBILE,
    loginState: {
      phoneNumber: "",
      otpCode: "",
    },
    loginStateError: {
      phoneNumberError: "",
      otpCodeError: "",
    },
  };

  handleLoading = (loading: boolean) => {
    this.setState({
      loading,
    });
  };

  handleLoggingIn = (isLoggingIn: boolean) => {
    this.setState({ isLoggingIn });
  };

  handleChangePage = (page: AuthState) => {
    this.setState({
      page,
    });
  };

  handleNavigate = (path: string) => {
    this.props.navigate(path);
    window.scroll({ top: 0, left: 0 });
  };

  handleOnChange = (e: any) => {
    if (this.state.isLoggingIn && e.target.id === "phoneNumber") {
      return;
    }

    const clonedLoginState = JSON.parse(JSON.stringify(this.state.loginState));
    clonedLoginState[e.target.id] = e.target.value;
    this.setState({
      loginState: clonedLoginState,
    });
  };

  handleMobileValidate = () => {
    const clonedLoginStateError = JSON.parse(
      JSON.stringify(this.state.loginStateError)
    );
    let typeList = [];
    if (this.state.page === AuthState.MOBILE) {
      typeList.push("phoneNumber");
    } else {
      typeList.push("otpCode");
    }

    handleLoginCondition(
      this.state.loginState,
      clonedLoginStateError,
      typeList
    );

    this.setState(
      {
        loginStateError: clonedLoginStateError,
      },
      async () => {
        if (this.state.page === AuthState.MOBILE) {
          if (!this.state.loginStateError.phoneNumberError) {
            this.handleLoading(true);
            const userExists = await getUserInfo(
              "+" + this.state.loginState.phoneNumber
            );
            if (userExists) {
              this.handleLoading(false);
              this.handleLoggingIn(true);
              const result = await signIn(
                "+" + this.state.loginState.phoneNumber
              );
              if (!result) {
                this.handleChangePage(AuthState.OTP);
              }
            } else {
              const clonedLoginStateError = JSON.parse(
                JSON.stringify(this.state.loginStateError)
              );
              clonedLoginStateError["phoneNumberError"] =
                "Installer number is not found";
              this.setState({
                loginStateError: clonedLoginStateError,
              });
              this.handleLoading(false);
            }
          }
        } else {
          if (!this.state.loginStateError.otpCodeError) {
            this.handleLoading(true);
            const clonedLoginStateError = JSON.parse(
              JSON.stringify(this.state.loginStateError)
            );
            const response = await verifyOTP(this.state.loginState.otpCode);
            if (response) {
              clonedLoginStateError["otpCodeError"] =
                "Invalid OTP, Please enter the OTP code again";
              this.handleLoading(false);
            } else {
              clonedLoginStateError["otpCodeError"] = "";
            }
            this.setState({
              loginStateError: clonedLoginStateError,
            });
          }
        }
      }
    );
  };

  renderActionButton = () => {
    let renderBool = false;
    if (this.state.page === AuthState.MOBILE) {
      if (!this.state.isLoggingIn) {
        renderBool = true;
      }
    } else {
      renderBool = true;
    }

    if (renderBool) {
      return (
        <div className="pt-6">
          <Button
            type="normal"
            text="Next"
            onClick={this.handleMobileValidate}
            className="w-full"
          />
        </div>
      );
    }
  };

  renderContent = () => {
    return (
      <>
        {this.state.page === AuthState.MOBILE ? (
          <div>
            <label
              htmlFor="phoneNumber"
              className="block text-sm font-medium text-gray-700"
            >
              Mobile Number
            </label>
            <div className="mt-1">
              <Input
                id="phoneNumber"
                className="mt-1"
                placeholder="60123456789"
                value={this.state.loginState.phoneNumber}
                error={this.state.loginStateError.phoneNumberError}
                onChange={this.handleOnChange}
              />
            </div>
            <div id="recaptcha-container" className="mt-4"></div>
          </div>
        ) : (
          <div>
            <label
              htmlFor="otpCode"
              className="block text-sm font-medium text-gray-700"
            >
              Enter OTP
            </label>
            <div className="mt-1">
              <Input
                id="otpCode"
                className="mt-1"
                placeholder="Enter your OTP code"
                value={this.state.loginState.otpCode}
                error={this.state.loginStateError.otpCodeError}
                onChange={this.handleOnChange}
              />
            </div>
          </div>
        )}

        {this.renderActionButton()}

        <div className="sm:col-span-2 sm:flex sm:justify-end">
          <span className="text-xs text-gray-500">
            Version {packageJson.version}
          </span>
        </div>
      </>
    );
  };

  render() {
    return (
      <>
        <Overlay
          loading={this.state.loading}
          text="Please wait while we are verifying your account"
        />
        <div className="min-h-screen bg-white flex">
          <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
            <div className="mx-auto w-full max-w-sm lg:w-96">
              <div>
                <div className="flex justify-center">
                  <img
                    className="h-24 w-auto"
                    src={verdantLogo}
                    alt="Verdant Logo"
                  />
                </div>
                <h2 className="mt-4 text-3xl font-extrabold text-verdantGreen-900">
                  Sign in to your account
                </h2>
              </div>
              <div className="mt-6 space-y-6">{this.renderContent()}</div>
            </div>
          </div>
          <div className="hidden lg:block relative w-0 flex-1">
            <img
              className="absolute inset-0 h-full w-full object-cover"
              src={verdantImage}
              alt=""
            />
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(withSecurity(LoginScreen));
