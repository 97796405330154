import React from "react";
import { useSelector } from "react-redux";
import { authStateInterface } from "../components/store/reducers/authReducer";

export const withSecurity = (Component: any) => {
  const Wrapper = (props: any) => {
    const authStore = useSelector(
      (state: { authStore: authStateInterface }) => state.authStore
    );

    React.useEffect(() => {
      if (!authStore.userLoading) {
        if (!authStore.userAuth && props.location.pathname !== "/") {
          props.navigate("/");
        } else if (
          authStore.userAuth &&
          authStore.user &&
          props.location.pathname === "/"
        ) {
          props.navigate("/dashboard/schedules");
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authStore]);

    if (props.location.pathname !== "/") {
      if (authStore.userAuth && authStore.user) {
        return <Component {...props} />;
      } else {
        return null;
      }
    } else {
      return <Component {...props} />;
    }
  };
  return Wrapper;
};
