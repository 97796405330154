import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "../../navigator/NavigateWrapper";
import { withSecurity } from "../../navigator/SecurityWrapper";
import Header from "../dashboard/Header";
import ScheduleEditor from "../pages/ScheduleEditor";
import Schedules from "../pages/Schedules";
import { authStateInterface } from "../store/reducers/authReducer";

export enum DashboardPage {
  SCHEDULES = "Schedules",
  SCHEDULEEDITOR = "Schedule Editor",
}

interface Props {
  location: any;
  authStore: authStateInterface;
}

interface State {
  page: DashboardPage | null;
}

class Dashboard extends Component<Props> {
  state: State = {
    page: null,
  };

  componentDidMount() {
    this.handlePageNavigation();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.handlePageNavigation();
    }
  }

  handlePageNavigation = () => {
    const path = window.location.pathname;
    let page: DashboardPage | null = null;
    if (path.includes("dashboard/schedules")) {
      page = DashboardPage.SCHEDULES;
    } else if (path.includes("dashboard/scheduleEditor")) {
      page = DashboardPage.SCHEDULEEDITOR;
    }
    this.setState({
      page,
    });
  };

  renderContent = () => {
    switch (this.state.page) {
      case DashboardPage.SCHEDULES:
        return <Schedules />;
      case DashboardPage.SCHEDULEEDITOR:
        return <ScheduleEditor authStore={this.props.authStore} />;
      default:
        return null;
    }
  };

  render() {
    return (
      <>
        <div className="h-screen flex overflow-hidden bg-gray-100">
          <div className="flex-1 overflow-auto focus:outline-none">
            <Header page={this.state.page} />
            <main className="flex-1 relative pb-8 z-0 overflow-y-auto">
              {this.renderContent()}
            </main>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
  };
};

export default connect(mapStateToProps)(withRouter(withSecurity(Dashboard)));
