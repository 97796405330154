import { FolderIcon, HomeIcon } from "@heroicons/react/solid";
import moment from "moment";
import React, { Component } from "react";
import { Journey } from "../../models/Journey";
import UserModel from "../../models/User";
import { withRouter } from "../../navigator/NavigateWrapper";
import Button from "../base/Button";
import ConfirmationModal from "../base/ConfirmationModal";
import Overlay from "../base/Overlay";
import {
  getAppointment,
  getImageWithFolderId,
  updatePEWhatsapp,
  uploadImage,
} from "../store/actions/appointmentActions";
import { getUser } from "../store/actions/userActions";
import { authStateInterface } from "../store/reducers/authReducer";

interface Props {
  navigate: any;
  authStore: authStateInterface;
}

interface State {
  loading: boolean;
  loadingText: string;
  selectedSchedule: Journey;
  selectedUser: UserModel;
  installerImageList: string[];
  confirmationModalVisible: boolean;
  pdfList: any[];
}

class ScheduleEditor extends Component<Props> {
  private fileInputRef = React.createRef<HTMLInputElement>();
  state: State = {
    loading: true,
    loadingText: "Please wait while we are retrieving your data",
    selectedSchedule: {
      id: "",
      name: "",
      phone1: "",
      installer: "",
      photos: "",
      address: {
        lat: 0,
        lng: 0,
        name: "",
      },
      pvSystem: "",
      pvModel: "",
      savings: 0,
      bill: 0,
      installLink: "",
      process: {
        step_deposit: false,
        step_document_checklist: false,
        step_document_drive: "",
        step_drawing_drive: "",
        step_document_ic: false,
        step_document_ssm: false,
        step_document_tax: false,
        step_document_tnb_bill: false,
        step_document_tnb_meter: false,
        step_half_pay: false,
        step_half_pay_link: "",
        step_installation_checklist: false,
        step_installation_date: moment().toDate(),
        step_installation_status: "",
        step_installation_remarks: "",
        step_nem_checklist: "",
        step_partial_pay: false,
        step_partial_pay_link: "",
        step_tnb_checklist: false,
        step_whatsapp_group: "",
        step_completed: false,
        step_warranty_drive: "",
      },
    },
    selectedUser: {
      id: "",
      name: "",
      phone1: "",
      phone2: "",
      phone3: "",
      userType: "",
    },
    installerImageList: [],
    pdfList: [],
    confirmationModalVisible: false,
  };

  componentDidMount = async () => {
    this.handleGetSelectedAppointment();
  };

  handleWhatsApp = async () => {
    this.handleLoading(true, "");

    await updatePEWhatsapp(this.state.selectedSchedule.id, "CHAT");

    window.open(
      `https://api.whatsapp.com/send?phone=${this.state.selectedSchedule.phone1}&text=Hi, this is Verdant Installer Team texting you for your solar PV installation (${this.state.selectedSchedule.name}) at ${this.state.selectedSchedule.address.name}.`
    );
    // if (!response) {
    //   window.open(
    //     `https://api.whatsapp.com/send?phone=${this.state.selectedSchedule.phone1}&text=Hi, this is Verdant Installer Team texting you for your solar PV installation (${this.state.selectedSchedule.name}) at ${this.state.selectedSchedule.address.name}.`
    //   );
    // } else {
    //   window.alert("Unknown error, please consult Verdant team");
    // }
    this.handleLoading(false, "");
  };

  handleGetSelectedAppointment = async () => {
    const searchParams = new URLSearchParams(window.location.search);
    const selectedId = searchParams.get("id");
    if (selectedId) {
      const selectedSchedule = await getAppointment(selectedId);
      if (typeof selectedSchedule !== "string" && selectedSchedule?.id) {
        // const scheduleLimitDay = moment(
        //   selectedSchedule.process.step_installation_date
        // ).add(30, "days");
        if (
          this.props.authStore.user?.name === selectedSchedule.installer &&
          selectedSchedule.process.step_installation_status === "Confirmed"
          // moment().isBefore(scheduleLimitDay)
        ) {
          this.setState({ selectedSchedule }, () => {
            this.handleLoading(false, "");
            this.handleGetSelectedUser();
            this.handleGetDrawingList();
            this.handleGetImageList();
          });
        } else {
          this.handleBack();
        }
      } else {
        this.handleBack();
      }
    }
  };

  handleGetSelectedUser = async () => {
    const selectedUser = await getUser(this.state.selectedSchedule.phone1);
    if (typeof selectedUser !== "string" && selectedUser?.id) {
      this.setState({ selectedUser });
    } else {
      this.handleBack();
    }
  };

  handleGetDrawingList = async () => {
    const drawingLink = this.state.selectedSchedule.process.step_drawing_drive;
    if (drawingLink) {
      const folderId = drawingLink
        .substring(drawingLink.lastIndexOf("/") + 1)
        .replaceAll("?usp=sharing", "");
      const response = await getImageWithFolderId(folderId, true);
      if (Object.keys(response).includes("imageURL")) {
        this.setState({
          pdfList: response.imageURL,
        });
      }
    }
  };

  handleGetImageList = async () => {
    if (this.state.selectedSchedule.installLink) {
      const folderId = this.state.selectedSchedule.installLink
        .substring(this.state.selectedSchedule.installLink.lastIndexOf("/") + 1)
        .replaceAll("?usp=sharing", "");
      const response = await getImageWithFolderId(folderId);
      if (Object.keys(response).includes("imageURL")) {
        this.setState({
          installerImageList: response.imageURL,
        });
      }
    }
  };

  handleUploadChange = async (selectedFiles: FileList | null) => {
    let uploadResponse = 0;
    this.handleLoading(true, "Please wait while we are updating your data");
    if (selectedFiles?.length !== undefined && selectedFiles.length > 0) {
      if (this.state.selectedSchedule.installLink) {
        const folderId = this.state.selectedSchedule.installLink
          .substring(
            this.state.selectedSchedule.installLink.lastIndexOf("/") + 1
          )
          .replaceAll("?usp=sharing", "");
        await Promise.all(
          Array.from(selectedFiles).map(async (eachFile) => {
            const response = await uploadImage(eachFile, folderId);
            if (!response) {
              uploadResponse += 1;
            }
            return "";
          })
        );
        if (uploadResponse) {
          if (this.fileInputRef?.current?.value !== undefined) {
            this.fileInputRef.current.value = "";
          }
          await updatePEWhatsapp(this.state.selectedSchedule.id, "UPLOAD");
          this.handleGetSelectedAppointment();
          window.alert(`You had uploaded ${uploadResponse} photo(s)`);
        } else {
          window.alert("Unknown Error, Please try again later");
        }
      } else {
        if (this.fileInputRef?.current?.value !== undefined) {
          this.fileInputRef.current.value = "";
        }
        window.alert(
          "Opps, Please consult Verdant Team to add Google Drive Link"
        );
      }
      this.handleLoading(false, "");
    }
  };

  handleLoading = (loading: boolean, loadingText: string) => {
    this.setState({
      loading,
      loadingText,
    });
  };

  handleBack = () => {
    this.props.navigate(`/dashboard/schedules`);
  };

  handleConfirmationModalVisible = (visible: boolean) => {
    this.setState({
      confirmationModalVisible: visible,
    });
  };

  handleNavigate = async () => {
    let locLat = 0;
    let locLng = 0;
    if (
      this.state.selectedSchedule.address.lat &&
      this.state.selectedSchedule.address.lng
    ) {
      locLat = this.state.selectedSchedule.address.lat;
      locLng = this.state.selectedSchedule.address.lng;
    } else if (window.google) {
      try {
        const geocoder = new window.google.maps.Geocoder();
        let { results } = await geocoder.geocode({
          address: this.state.selectedSchedule.address.name,
        });
        locLat = results[0].geometry.location.lat();
        locLng = results[0].geometry.location.lng();
      } catch (err) {}
    }

    if (locLat && locLng) {
      if (
        navigator.platform.indexOf("iPhone") !== -1 ||
        navigator.platform.indexOf("iPod") !== -1 ||
        navigator.platform.indexOf("iPad") !== -1
      )
        window.open(
          `maps://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${locLat},${locLng}`
        );
      else
        window.open(
          `https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${locLat},${locLng}`
        );
    } else {
      window.alert(
        "Unable to find customer address, please search at Google Maps manually"
      );
    }
  };

  renderInstallerImage = () => {
    let imageList: JSX.Element[] = [];
    if (this.state.installerImageList.length > 0) {
      this.state.installerImageList.map((eachImage) => {
        imageList.push(
          <li key={eachImage} className="relative">
            <div className="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 overflow-hidden">
              <img
                src={eachImage}
                alt=""
                className="object-cover pointer-events-none"
              />
            </div>
          </li>
        );
        return "";
      });
    }
    return imageList;
  };

  render() {
    return (
      <>
        <Overlay loading={this.state.loading} text={this.state.loadingText} />
        <ConfirmationModal
          onClose={this.handleConfirmationModalVisible}
          title="Send Whatsapp Message To Customer"
          open={this.state.confirmationModalVisible}
          content={`Read the instructions below carefully: \n\n 1.By clicking "Confirm", you will be directed to Whatsapp App \n\n 2.Once navigated to the Whatsapp App, send the template message to the customer and share your live location \n\n 3.By clicking "Confirm", Verdant team will be notified on your installation update too. Only click "Confirm" on the installation date`}
          onClick={this.handleWhatsApp}
        />
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col md:flex-row mt-8 justify-center items-center space-y-2 md:space-y-0">
            <div className="flex flex-row w-full md:w-auto justify-start items-center">
              <HomeIcon
                className="w-8 h-8 text-verdantGreen-600 mr-2 cursor-pointer hover:text-verdantGreen-700"
                onClick={this.handleBack}
              />
              <span className="font-bold text-xl sm:text-2xl ">
                &gt; Selected Appointment
              </span>
            </div>
            <div className="flex-1"></div>
            <Button
              onClick={this.handleConfirmationModalVisible.bind(this, true)}
              text="Message Customer"
              type="normal"
              size="small"
              className="w-full md:w-auto"
            />
          </div>
          <div className="block mt-8 w-full ">
            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Customer Information
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  Solar PV installation details
                </p>
              </div>
              <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                <dl className="sm:divide-y sm:divide-gray-200">
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Appointment Name
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {this.state.selectedSchedule.name}
                    </dd>
                  </div>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Name</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {this.state.selectedUser.name}
                    </dd>
                  </div>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Mobile No
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {this.state.selectedUser.phone1} ,{" "}
                      {this.state.selectedUser.phone2} ,{" "}
                      {this.state.selectedUser.phone3}
                    </dd>
                  </div>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Installation Date
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {moment(
                        this.state.selectedSchedule.process
                          .step_installation_date
                      ).format("DD-MM-YY")}
                    </dd>
                  </div>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Address
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {this.state.selectedSchedule.address.name}
                      <span
                        className="cursor-pointer font-bold ml-4 text-verdantGreen-600 hover:text-verdantGreen-700"
                        onClick={this.handleNavigate}
                      >
                        Navigate
                      </span>
                    </dd>
                  </div>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      PV System (kWp)
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {this.state.selectedSchedule.pvSystem}
                    </dd>
                  </div>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      PV Model
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {this.state.selectedSchedule.pvModel}
                    </dd>
                  </div>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Uploaded Pictures
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <span
                        className="cursor-pointer font-bold text-verdantGreen-600 hover:text-verdantGreen-700"
                        onClick={() => {
                          this.fileInputRef?.current?.click();
                        }}
                      >
                        Upload
                      </span>
                    </dd>

                    <input
                      ref={this.fileInputRef}
                      id="image"
                      className="hidden"
                      multiple
                      type="file"
                      accept=".png,.jpg,.jpeg"
                      onChange={(e) => {
                        this.handleUploadChange(e?.target?.files);
                      }}
                    />
                  </div>
                </dl>
              </div>
            </div>
            {this.state.pdfList.length > 0 && (
              <div className="block mt-5 w-full">
                <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                  <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Installation Drawings
                    </h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                      Schematic drawing for installation
                    </p>
                  </div>
                  <div className="border-t border-gray-200">
                    <ul className="divide-y divide-gray-200">
                      {this.state.pdfList.map((eachPdf, index) => (
                        <li key={index}>
                          <div className="block hover:bg-gray-50">
                            <div className="flex items-center px-4 py-4 sm:px-6">
                              <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                                <div className="truncate">
                                  <div className="flex text-sm">
                                    <FolderIcon
                                      className="mr-1.5 h-6 w-6 flex-shrink-0 text-gray-500"
                                      aria-hidden="true"
                                    />
                                    <p className="truncate font-medium text-gray-700">
                                      {eachPdf.name}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="ml-5 flex-shrink-0">
                                <a
                                  href={eachPdf.url}
                                  target="_blank"
                                  rel="noreferrer"
                                  style={{ textDecorationLine: "none" }}
                                >
                                  <Button
                                    size="small"
                                    text="Download"
                                    type="normal"
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            )}
            {this.state.installerImageList.length > 0 && (
              <div className="block mt-5 w-full">
                <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                  <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Installation Images
                    </h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                      All your uploaded pictures can be viewed below
                    </p>
                  </div>
                  <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                    <ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4">
                      {this.renderInstallerImage()}
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(ScheduleEditor);
