import axios from "axios";
import UserModel from "../../../models/User";
import { getAPIUrl } from "../../../utils/database";

export const getUser = async (phone1: string) => {
  try {
    const userResponse = await axios.post(`${getAPIUrl()}/user/get`, {
      phone: phone1,
      token: process.env.REACT_APP_API_TOKEN,
    });

    return userResponse.data.user as UserModel;
  } catch (err) {
    return "Unknown error, please contact developer at info@blixify.co if this continues";
  }
};
