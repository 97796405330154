import { Disclosure, Menu, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import VerdantFavicon from "../../assets/verdant_favicon.png";
import Logo from "../../assets/verdant_header_logo.png";
import { DashboardPage } from "../screens/Dashboard";
import { handleSignOut } from "../store/actions/authActions";

interface Props {
  page: DashboardPage | null;
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default class Header extends Component<Props> {
  render() {
    return (
      <Disclosure as="nav" className="bg-white shadow">
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
              <div className="relative flex items-center justify-between h-16">
                <div className="flex items-center px-2 lg:px-0">
                  <div className="flex-shrink-0">
                    <img
                      className="block w-auto h-8 sm:h-9"
                      src={Logo}
                      alt="Verdant Logo"
                    />
                  </div>
                  <div className="hidden lg:block lg:ml-6">
                    <div className="flex space-x-4">
                      <Link
                        to={"/dashboard/schedules"}
                        className={classNames(
                          this.props.page === "Schedules"
                            ? "border-verdantGreen-900 text-verdantGreen-900"
                            : "border-transparent text-verdantGreen-600 hover:text-verdantGreen-700",
                          "inline-flex font-bold items-center px-1 py-1 border-b-2 text-sm font-medium"
                        )}
                      >
                        Appointments
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="flex lg:hidden">
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-verdantGreen-600 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="hidden lg:block lg:ml-4">
                  <div className="flex items-center">
                    <Menu as="div" className="ml-4 relative flex-shrink-0">
                      <div>
                        <Menu.Button className="bg-gray-800 rounded-full flex text-sm text-white focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-offset-verdantGreen-600 focus:ring-verdantGreen-600">
                          <span className="sr-only">Open user menu</span>
                          <img
                            className="h-8 w-8 rounded-full bg-verdantGreen-600"
                            src={VerdantFavicon}
                            alt="Verdant Profile"
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 z-50 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <Menu.Item>
                            {({ active }) => (
                              <p
                                onClick={handleSignOut}
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "cursor-pointer block px-4 py-2 text-sm text-gray-700"
                                )}
                              >
                                Sign out
                              </p>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="lg:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1">
                <Disclosure.Button
                  as="a"
                  href="/dashboard/schedules"
                  className="bg-verdantGreen-600 text-white block px-3 py-2 rounded-md text-base font-medium"
                >
                  Appointments
                </Disclosure.Button>
                <Disclosure.Button
                  onClick={handleSignOut}
                  className="block px-3 py-2 rounded-md text-base font-medium text-verdantGreen-600 hover:text-verdantGreen-700"
                >
                  Sign out
                </Disclosure.Button>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    );
  }
}
