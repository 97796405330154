import axios from "axios";
import { Journey } from "../../../models/Journey";
import { getAPIUrl } from "../../../utils/database";

export const getAppointment = async (selectedId: string) => {
  try {
    const scheduleResponse = await axios.post(
      `${getAPIUrl()}/appointment/get`,
      { itemId: selectedId, token: process.env.REACT_APP_API_TOKEN }
    );

    return scheduleResponse.data.journey as Journey;
  } catch (err) {
    return "Unknown error, please contact developer at info@blixify.co if this continues";
  }
};

export const getAppointmentList = (name: string) => {
  return async (dispatch: any, getState: any) => {
    try {
      updateAppointmentLoadingState(dispatch, true);
      const appointmentListResponse = await axios.post(
        `${getAPIUrl()}/appointment/installerList`,
        {
          installerName: name,
          token: process.env.REACT_APP_API_TOKEN,
        }
      );

      const appointmentList: Journey[] = appointmentListResponse.data.journey;
      appointmentList.sort(function (a, b) {
        return (
          new Date(b.process.step_installation_date).getTime() -
          new Date(a.process.step_installation_date).getTime()
        );
      });

      dispatch({
        type: "UPDATE_APPOINTMENT_LIST",
        payload: {
          appointments: appointmentList,
        },
      });
      updateAppointmentLoadingState(dispatch, false);
    } catch (err) {
      updateAppointmentLoadingState(dispatch, false);
      return err.message;
    }
  };
};

export const clearAppointments = () => {
  return (dispatch: any, getState: any) => {
    dispatch({
      type: "UPDATE_APPOINTMENT_LIST",
      payload: {
        appointments: [],
      },
    });
  };
};

export const uploadImage = async (file: any, folderId: string) => {
  try {
    await axios.post(
      `${getAPIUrl()}/drive/upload`,
      { file, folderId, token: process.env.REACT_APP_API_TOKEN },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return "";
  } catch (err) {
    return "Unknown error, please contact developer at info@blixify.co if this continues";
  }
};

export const getImageWithFolderId = async (folderId: string, pdf?: boolean) => {
  try {
    const query: any = {
      folderId,
      token: process.env.REACT_APP_API_TOKEN,
    };
    if (pdf) {
      query["pdf"] = true;
    }
    const responseImageList = await axios.post(
      `${getAPIUrl()}/drive/list`,
      query
    );
    return responseImageList.data;
  } catch (err) {
    return "Unknown error, please contact developer at info@blixify.co if this continues";
  }
};

export const updatePEWhatsapp = async (appointmentId: string, type: string) => {
  try {
    await axios.post(`${getAPIUrl()}/webhook/whatsapp`, {
      appointmentId: appointmentId,
      type: type,
      token: process.env.REACT_APP_API_TOKEN,
    });
    return "";
  } catch (err) {
    return "Unknown error, please contact developer at info@blixify.co if this continues";
  }
};

const updateAppointmentLoadingState = (dispatch: any, loading: boolean) => {
  dispatch({
    type: "UPDATE_APPOINTMENT_LOADING",
    payload: {
      loading: loading,
    },
  });
};
