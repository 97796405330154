import { Journey } from "../../../models/Journey";

export interface appointmentStateInterface {
  appointments: Journey[];
  loading: boolean;
}

const initState: appointmentStateInterface = {
  appointments: [],
  loading: true,
};

const appointmentReducer: any = (state = initState, action: any) => {
  switch (action.type) {
    case "UPDATE_APPOINTMENT_LIST":
      return {
        ...state,
        appointments: action.payload.appointments,
      };
    case "UPDATE_APPOINTMENT_LOADING":
      return {
        ...state,
        loading: action.payload.loading,
      };
    default:
      return state;
  }
};

export default appointmentReducer;
