import { SearchIcon } from "@heroicons/react/solid";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Journey } from "../../models/Journey";
import { withRouter } from "../../navigator/NavigateWrapper";
import Input from "../base/Input";
import Select, { Option } from "../base/Select";
import Table, { TableHeader } from "../base/Table";
import {
  clearAppointments,
  getAppointmentList,
} from "../store/actions/appointmentActions";
import { appointmentStateInterface } from "../store/reducers/appointmentReducer";
import { authStateInterface } from "../store/reducers/authReducer";

enum Months {
  "JANUARY" = "January",
  "FEBRUARY" = "February",
  "MARCH" = "March",
  "APRIL" = "April",
  "MAY" = "May",
  "JUNE" = "June",
  "JULY" = "July",
  "AUGUST" = "August",
  "SEPTEMBER" = "September",
  "OCTOBER" = "October",
  "NOVEMBER" = "November",
  "DECEMBER" = "December",
}

enum ButtonGroupType {
  "UPCOMING" = "Upcoming appointment",
  "COMPLETED" = "Completed appointment",
}

enum ButtonSortType {
  "OLDEST" = "Previous appointment",
  "LATEST" = "Latest appointment",
}

interface Props {
  navigate: any;
  appointmentStore: appointmentStateInterface;
  authStore: authStateInterface;
  getAppointmentList: (name: string) => void;
  clearAppointments: () => void;
}

interface State {
  selectedButton: keyof typeof ButtonGroupType;
  scheduleMonths: keyof typeof Months | undefined;
  selectedSortButton: keyof typeof ButtonSortType;
  searchText: string;
}

class Schedules extends Component<Props> {
  state: State = {
    selectedButton: "UPCOMING",
    selectedSortButton: "LATEST",
    scheduleMonths: undefined,
    searchText: "",
  };

  componentDidMount = async () => {
    this.props.getAppointmentList(this.props.authStore.user?.name ?? "");
  };

  handleSelectedData = (id: string) => {
    this.props.navigate(`/dashboard/scheduleEditor?id=${id}`);
  };

  handleFilterMonths = (id: string, key: string) => {
    this.setState({ scheduleMonths: key });
  };

  handleSortType = (selectedSortButton: keyof typeof ButtonSortType) => {
    this.setState({ selectedSortButton });
  };

  handleProjectType = (selectedButton: keyof typeof ButtonGroupType) => {
    this.setState({ selectedButton });
  };

  handleFilter = (e: any) => {
    this.setState({ searchText: e.target.value });
  };

  renderFilteredData = () => {
    const appointmentList: any = this.props.appointmentStore.appointments;

    let filteredList: any = [];
    let listView: any = [];
    // INFO : Sort Appointment Based on Installation Date
    appointmentList.sort((a: Journey, b: Journey) => {
      if (this.state.selectedSortButton === "LATEST") {
        return (
          new Date(b.process.step_installation_date).getTime() -
          new Date(a.process.step_installation_date).getTime()
        );
      } else {
        return (
          new Date(a.process.step_installation_date).getTime() -
          new Date(b.process.step_installation_date).getTime()
        );
      }
    });

    //INFO : Filter to see confirmed installation only
    const confirmedList = appointmentList.filter(
      (eachAppointment: Journey) =>
        eachAppointment.process.step_installation_status === "Confirmed"
    );

    //INFO: Filter Status, save filtered data on appointment list
    filteredList = confirmedList.filter((eachAppointment: Journey) => {
      if (this.state.selectedButton === "UPCOMING") {
        return eachAppointment.process.step_installation_checklist === false;
      } else if (this.state.selectedButton === "COMPLETED") {
        return eachAppointment.process.step_installation_checklist === true;
      } else {
        return "";
      }
    });

    //INFO : Filter to only able to see past 30 days data
    // filteredList = filteredList.filter((eachAppointment: Journey) => {
    //   return moment().isBefore(
    //     moment(eachAppointment.process.step_installation_date).add(30, "days")
    //   );
    // });

    //INFO: Get existing filtered data on appointment list, filter search text
    if (this.state.searchText) {
      filteredList = filteredList.filter((eachAppoinment: Journey) => {
        return (
          eachAppoinment.name
            .toUpperCase()
            .includes(this.state.searchText.toUpperCase().trim()) ||
          eachAppoinment.phone1.includes(this.state.searchText.trim())
        );
      });
    }

    //INFO: Get existing filtered data on appointment list, filter months
    if (this.state.scheduleMonths) {
      filteredList = filteredList.filter((eachAppointment: Journey) => {
        return (
          moment(eachAppointment.process.step_installation_date)
            .format("MMMM")
            .toUpperCase() === this.state.scheduleMonths
        );
      });
    }

    if (filteredList.length > 0) {
      filteredList.map((eachAppointment: Journey) => {
        listView.push({
          id: eachAppointment.id,
          name: eachAppointment.name,
          mobile: eachAppointment.phone1,
          address: eachAppointment.address.name,
          pvSystem: eachAppointment.pvSystem,
          pvModel: eachAppointment.pvModel,
          date: moment(eachAppointment.process.step_installation_date).format(
            "DD-MM-YY"
          ),
        });
        return "";
      });
    }

    return listView;
  };

  renderMonthsOptions = () => {
    const monthsOptions: Option[] = [];
    if (this.state.scheduleMonths) {
      monthsOptions.push({
        key: "",
        title: "Clear Selection",
      });
    }
    Object.keys(Months).map((eachMonthsKey) => {
      monthsOptions.push({
        key: eachMonthsKey,
        title: Months[eachMonthsKey as keyof typeof Months],
      });
      return null;
    });
    return monthsOptions;
  };

  renderMonthsSelection = () => {
    return (
      <div className="w-48 ml-0 mt-2 sm:mt-0 sm:ml-2 lg:ml-0">
        <Select
          id="selectedMonths"
          placeholder="Filter by months"
          value={this.state.scheduleMonths ?? ""}
          options={this.renderMonthsOptions()}
          onChange={this.handleFilterMonths}
          error={""}
        />
      </div>
    );
  };

  renderToggleButton = () => {
    const selectedButtonGroup =
      "z-10 ring-1 ring-verdantGreen-600 border-verdantGreen-600";
    return (
      <>
        <span className="rounded-md h-12 flex flex-row sm:mr-2 ">
          <button
            type="button"
            className={`relative inline-flex items-center px-3 py-3 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none ${
              this.state.selectedButton === "UPCOMING"
                ? selectedButtonGroup
                : ""
            }`}
            onClick={this.handleProjectType.bind(this, "UPCOMING")}
          >
            Upcoming
          </button>
          <button
            type="button"
            className={`-ml-px relative inline-flex items-center px-3 py-3 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none ${
              this.state.selectedButton === "COMPLETED"
                ? selectedButtonGroup
                : ""
            }`}
            onClick={this.handleProjectType.bind(this, "COMPLETED")}
          >
            Completed
          </button>
        </span>
        <span className="rounded-md h-12 flex flex-row mt-2 ml-0 sm:mr-2 sm:mt-0 sm:ml-2 lg:ml-0 ">
          <button
            type="button"
            className={`relative inline-flex items-center px-[26px] py-3 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none ${
              this.state.selectedSortButton === "OLDEST"
                ? selectedButtonGroup
                : ""
            }`}
            onClick={this.handleSortType.bind(this, "OLDEST")}
          >
            Oldest
          </button>
          <button
            type="button"
            className={`-ml -px relative inline-flex items-cnter px-[26px] py-3 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none ${
              this.state.selectedSortButton === "LATEST"
                ? selectedButtonGroup
                : ""
            }`}
            onClick={this.handleSortType.bind(this, "LATEST")}
          >
            Latest
          </button>
        </span>
      </>
    );
  };

  renderSearchBar = () => {
    return (
      <>
        <div className="flex w-full">
          <label htmlFor="search" className="sr-only">
            Search
          </label>
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <SearchIcon className="h-5 w-5 text-white" aria-hidden="true" />
          </div>
          <Input
            id="id"
            className="h-12 w-96 lg:w-full"
            placeholder="Search Appointment"
            value={this.state.searchText}
            onChange={this.handleFilter}
          />
        </div>
      </>
    );
  };

  render() {
    const headers: TableHeader[] = [
      {
        key: "id",
        title: "Id",
      },
      {
        key: "name",
        title: "Name",
      },
      {
        key: "mobile",
        title: "Mobile",
      },
      {
        key: "date",
        title: "Install Date",
      },
      {
        key: "address",
        title: "Address",
      },
      {
        key: "pvSystem",
        title: "Size (kWp)",
      },
      {
        key: "pvModel",
        title: "PV Model",
      },
    ];
    return (
      <>
        <div className="mt-8">
          <div className="flex justify-center max-w-full">
            <div className="max-w-7xl mx-auto w-full mb-4 px-4 sm:px-6 lg:px-8">
              <div className="flex flex-col lg:flex-row lg:items-center justify-center">
                <div className="flex flex-col mx-1 self-start">
                  <h1 className="font-bold text-xl text-verdantGreen-900 mt-4 sm:text-2xl">
                    {"Installer Appointments" +
                      ` (${this.props.authStore.user?.name})`}
                  </h1>
                  <p className="text-base text-gray-500">
                    Appointments are sorted from closest date
                  </p>
                </div>
                <div className="grow" />
                <div className="flex flex-col items-start">
                  <div className="flex flex-col items-center sm:flex-row my-2">
                    {this.renderToggleButton()}
                    {this.renderMonthsSelection()}
                  </div>
                  {this.renderSearchBar()}
                </div>
              </div>
            </div>
          </div>
          <Table
            dataLimit={10}
            header={headers}
            loading={false}
            pageIndex={1}
            lastCursor={""}
            data={this.renderFilteredData()}
            handlePage={() => {}}
            onClickRow={this.handleSelectedData}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    appointmentStore: state.appointmentStore,
    authStore: state.authStore,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getAppointmentList: (name: string) => dispatch(getAppointmentList(name)),
    clearAppointments: () => dispatch(clearAppointments()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Schedules));
