import axios from "axios";
import {
  onAuthStateChanged,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  signOut,
} from "firebase/auth";
import { getAPIUrl } from "../../../utils/database";
import { auth } from "../../../utils/firebase";
import { LoginState, LoginStateError } from "../../screens/LoginScreen";

let confirmationResult: any = null;

//INFO : Auth Listener
let unsubscribeAuthListener: any = null;

export const getAuthListener = () => {
  return async (dispatch: any, getState: any) => {
    if (!unsubscribeAuthListener) {
      unsubscribeAuthListener = onAuthStateChanged(auth, (user: any) => {
        if (user) {
          dispatch({
            type: "UPDATE_USER_AUTH",
            payload: {
              userAuth: user,
            },
          });
          getUserInfo(user.phoneNumber, dispatch);
        } else {
          dispatch({
            type: "UPDATE_USER_AUTH",
            payload: {
              userAuth: null,
            },
          });
          dispatch({
            type: "UPDATE_USER",
            payload: {
              user: null,
            },
          });
        }
      });
    }
  };
};

export const removeAuthListener = () => {
  return async (dispatch: any, getState: any) => {
    if (unsubscribeAuthListener) {
      unsubscribeAuthListener();
      unsubscribeAuthListener = null;
    }
  };
};

export const getUserInfo = async (phoneNumber: string, dispatch?: any) => {
  try {
    const phone = phoneNumber.substring(1);
    const mondayUser = await axios.post(`${getAPIUrl()}/installer/get`, {
      phone: phone,
      token: process.env.REACT_APP_API_TOKEN,
    });

    if (dispatch) {
      if (mondayUser.data.installer) {
        dispatch({
          type: "UPDATE_USER",
          payload: {
            user: mondayUser.data.installer,
          },
        });
      } else {
        handleSignOut();
      }
    } else {
      if (mondayUser.data.installer) {
        return true;
      }
    }

    return null;
  } catch (err) {
    return null;
  }
};

export const handleLoginCondition = (
  userLoginAtributeState: LoginState,
  userLoginAtributeError: LoginStateError,
  typeList: string[]
) => {
  typeList.map((type) => {
    switch (type) {
      case "phoneNumber":
        const phoneFilter = /^[0-9]+$/;
        if (userLoginAtributeState.phoneNumber.replace(/\s/g, "").length <= 0) {
          userLoginAtributeError["phoneNumberError"] =
            "Please enter your phone number (Eg 60123456789)";
        } else if (!phoneFilter.test(userLoginAtributeState.phoneNumber)) {
          userLoginAtributeError["phoneNumberError"] =
            "Please enter your phone number in correct format";
        } else {
          userLoginAtributeError["phoneNumberError"] = "";
        }
        break;
      case "otpCode":
        if (userLoginAtributeState.otpCode.replace(/\s/g, "").length <= 0) {
          userLoginAtributeError["otpCodeError"] =
            "Please enter the OTP Code sent to your contact number";
        } else {
          userLoginAtributeError["otpCodeError"] = "";
        }
        break;
    }
    return null;
  });
};

export const signIn = async (phoneNumber: string) => {
  const recaptchaVerifier = new RecaptchaVerifier(
    "recaptcha-container",
    {},
    auth
  );
  try {
    confirmationResult = await signInWithPhoneNumber(
      auth,
      phoneNumber,
      recaptchaVerifier
    );
    return "";
  } catch (err: any) {
    await recaptchaVerifier.render();
    return err.message;
  }
};

export const verifyOTP = async (code: string) => {
  try {
    await confirmationResult.confirm(code);
    confirmationResult = null;
    return "";
  } catch (err: any) {
    return err.message;
  }
};

export const handleSignOut = async () => {
  try {
    await signOut(auth);
  } catch (err) {}
};
